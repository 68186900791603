<template>
  <header class="bg-gray-900 text-white p-4">
    <div class="container mx-auto flex justify-between items-center">
      <div class="w-48 h-24 mb-0">
        <router-link to="/"><img src="/images/white-logo.png" alt="Logo"></router-link>
      </div>
      <div class="block md:hidden">
        <button @click="toggleMenu" class="text-white focus:outline-none">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>
      <nav :class="{ 'hidden': !isMenuOpen, 'md:flex': true, 'space-x-8': true }">
        <router-link to="/" class="font-retro hover:underline">Home</router-link>
        <router-link to="/about" class="font-retro hover:underline">About</router-link>
        <router-link to="/events" class="font-retro hover:underline">Events</router-link>
        <router-link to="/roster" class="font-retro hover:underline">Roster</router-link>
        <router-link to="/contact" class="font-retro hover:underline">Contact</router-link>
        <a href="http://www.prowrestlingtees.com/1upwrestling" target="_blank" class="font-retro hover:underline">Shop</a>
      </nav>
    </div>
    <nav :class="{ 'hidden': !isMobileMenuOpen, 'md:hidden': true, 'flex-col': true, 'space-y-2': true, 'mt-4': true }">
      <router-link to="/" class="font-retro hover:underline block" @click="toggleMenu">Home</router-link>
      <router-link to="/about" class="font-retro hover:underline block" @click="toggleMenu">About</router-link>
      <router-link to="/events" class="font-retro hover:underline block" @click="toggleMenu">Events</router-link>
      <router-link to="/roster" class="font-retro hover:underline block" @click="toggleMenu">Roster</router-link>
      <router-link to="/contact" class="font-retro hover:underline block" @click="toggleMenu">Contact</router-link>
      <a href="http://www.prowrestlingtees.com/1upwrestling" target="_blank" class="font-retro hover:underline">Shop</a>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isMenuOpen: false,
      isMobileMenuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    }
  }
}
</script>
