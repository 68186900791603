<template>
  <div>
    <section class="hero bg-red-600 text-white p-4 text-center">
      <img src="/images/logo-large.png" alt="1UP Wrestling" class="mx-auto my-6" />
    </section>
    <section class=" contact-info bg-white p-4 shadow-lg mt-4 border-4 border-gray-900">
      <h2 class="text-2xl font-retro text-white bg-gray-900 p-2 text-center">Contact Us</h2>
      <p class="mt-4 text-xl text-center">Phone: (315) 746-2337</p>
      <p class="mt-4 text-xl text-center">Email: <a href="mailto:agentfenton@1upwrestling.com">agentfenton@1upwrestling.com</a></p>
      <p class="mt-4 text-xl text-center">Or fill out the form below</p>
      <div v-if="messageSent" class="mt-4 p-4 bg-green-500 text-white">
        Thank you for your message. We will review and reach out to you soon.
      </div>
      <form @submit.prevent="submitMessage">
        <div class="mt-4">
          <label for="name" class="block text-gray-900">Name:</label>
          <input v-model="form.name" type="text" id="name" class="w-full p-2 border border-gray-300" />
          <p v-if="errors.name" class="text-red-500">{{ errors.name }}</p>
        </div>
        <div class="mt-4">
          <label for="email" class="block text-gray-900">Email:</label>
          <input v-model="form.email" type="email" id="email" class="w-full p-2 border border-gray-300" />
          <p v-if="errors.email" class="text-red-500">{{ errors.email }}</p>
        </div>
        <div class="mt-4">
          <label for="messageType" class="block text-gray-900">Message Type:</label>
          <select v-model="form.messageType" id="messageType" class="w-full p-2 border border-gray-300" @change="setMessageTypeName">
            <option value="" disabled>Select Message Type</option>
            <option v-for="type in messageTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
          </select>
          <p v-if="errors.messageType" class="text-red-500">{{ errors.messageType }}</p>
        </div>
        <div class="mt-4">
          <label for="message" class="block text-gray-900">Message:</label>
          <textarea v-model="form.message" id="message" class="w-full p-2 border border-gray-300"></textarea>
          <p v-if="errors.message" class="text-red-500">{{ errors.message }}</p>
        </div>
        <button type="submit" class="bg-red-600 text-white py-2 px-4 font-retro border-2 border-gray-900 mt-4">Submit</button>
      </form>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import apiClient from '@/api/axios';

export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        messageType: '',
        message: ''
      },
      messageTypes: [],
      errors: {},
      submitted: false,
      messageTypeName: '',
      messageSent: false
    };
  },
  methods: {
    async fetchMessageTypes() {
      const query = `
        query {
          messageTypes {
            id
            name
          }
        }
      `;
      const response = await apiClient.post('', { query });
      this.messageTypes = response.data.data.messageTypes;
    },
    validateForm() {
      this.errors = {};
      if (!this.form.name) {
        this.errors.name = 'Name is required';
      }
      if (!this.form.email) {
        this.errors.email = 'Email is required';
      } else if (!this.validateEmail(this.form.email)) {
        this.errors.email = 'Email must be valid';
      }
      if (!this.form.messageType) {
        this.errors.messageType = 'Message Type is required';
      }
      if (!this.form.message) {
        this.errors.message = 'Message is required';
      }
      return Object.keys(this.errors).length === 0;
    },
    validateEmail(email) {
      const re = /^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
      return re.test(String(email).toLowerCase());
    },
    setMessageTypeName() {
      const selectedType = this.messageTypes.find(type => type.id === this.form.messageType);
      this.messageTypeName = selectedType ? selectedType.name : '';
    },
    async submitMessage() {
      this.submitted = true;
      if (!this.validateForm()) {
        this.submitted = false;
        return;
      }

      const mutation = `
        mutation {
          createContactMessage(data: {
            name: "${this.form.name}"
            email: "${this.form.email}"
            messageType: { connect: { id: "${this.form.messageType}" } }
            message: "${this.form.message}"
          }) {
            id
          }
        }
      `;
      const response = await apiClient.post('', { query: mutation });
      const messageId = response.data.data.createContactMessage.id;
      await this.sendEmailNotification(messageId);
    },
    async sendEmailNotification(messageId) {
      const emailData = {
        to: ['agentfenton@1upwrestling.com', 'corey@cyndig.net'],
        subject: 'New Contact Message - 1UP',
        text: `You have a new (${this.messageTypeName}) message from ${this.form.name} (${this.form.email}).\n\nMessage: ${this.form.message}\n\nView Message: https://1upadmin.cynergydigital.net/contact-messages/${messageId}`
      };
      await axios.post('/api/send-email', emailData).then(() => {
        this.form.name = '';
        this.form.email = '';
        this.form.messageType = '';
        this.form.message = '';
        this.messageSent = true;
      });
    }
  },
  async created() {
    await this.fetchMessageTypes();
  }
};
</script>
