<template>
  <div>
    <section class="bg-white p-4 shadow-lg mt-4 border-4 border-gray-900">
      <h2 class="text-2xl font-retro text-white bg-gray-900 p-2 text-center">Choose Your Wrestler</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4">
        <WrestlerSummary v-for="wrestler in wrestlers" :key="wrestler.id" :wrestler="wrestler" />
      </div>
    </section>
  </div>
</template>

<script>
import apiClient from '@/api/axios';
import WrestlerSummary from '@/components/WrestlerSummary.vue';

export default {
  components: {
    WrestlerSummary
  },
  data() {
    return {
      wrestlers: [],
    };
  },
  methods: {
    async fetchWrestlers() {
      const query = `
        query {
          rosterItems(where: { isActive: { equals: true }, type: { equals: "wrestler" } }, orderBy: { name: asc }) {
            id
            name
            image {
              url
            }
          }
        }
      `;
      const response = await apiClient.post('', { query });
      this.wrestlers = response.data.data.rosterItems;
    }
  },
  async created() {
    await this.fetchWrestlers();
  },
};
</script>

<style scoped>
/* Additional styles if needed */
</style>
