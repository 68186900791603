<template>
  <div>   
    <EventSummary :event="nextEvent" :show-buttons="true" v-if="nextEvent"></EventSummary>
    <h2 class="my-6 text-2xl font-retro text-white bg-gray-900 p-2 text-center">Past Events</h2>   
    <div v-if="pastEvents">
      <EventSummary v-for="event in pastEvents" :key="event.id" :event="event"></EventSummary>
    </div> 
  </div>
</template>

<script>
import apiClient from '@/api/axios';
import EventSummary from '@/components/EventSummary.vue';
export default {
  components: {
    EventSummary
  },
  data() {
    return {
      nextEvent: null,
      pastEvents: null,
    };
  },
  methods: {
    async fetchNextEvent() {
      const today = new Date().toISOString();
      const query = `
        query {
          events(where: { isActive: { equals: true }, dateTime: { gte: "${today}" } }, orderBy: { dateTime: asc }, take: 1) {
            id
            name
            dateTime
            venue {
              name
              address
            }
            image {
              url
            }
            ticketUrl
          }
        }
      `;
      const response = await apiClient.post('', { query });
      this.nextEvent = response.data.data.events[0];
    },
    async fetchPastEvents() {
      const today = new Date().toISOString();
      const query = `
        query {
          events(where: { isActive: { equals: true }, dateTime: { lt: "${today}" } }, orderBy: { dateTime: desc }) {
            id
            name
            dateTime
            venue {
              name
              address
            }
            image {
              url
            }
          }
        }
      `;
      const response = await apiClient.post('', { query });
      this.pastEvents = response.data.data.events;
    }
  },
  async created() {
    await this.fetchNextEvent();
    await this.fetchPastEvents();
  },
};
</script>
