<template>
    <div class="pt-4 bg-red-600 border-4 border-gray-900">
        <img :src="wrestler.image.url" alt="Wrestler Image" class="mx-auto" />
        <router-link :to="`/roster/${wrestler.id}`" class="text-xl font-retro text-white hover:underline w-full inline-block bg-gray-900 p-2 text-center">{{ wrestler.name }}</router-link>
    </div>
</template>

<script>
export default {
  props: {
    wrestler: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
/* Additional styles if needed */
</style>
