<template>
  <div>
    <section class="hero bg-red-600 text-white p-4 md:py-8 lg:py-12 md:px-12 lg:px-16 text-center">
      <h1 class="text-2xl md:text-4xl xl:text-6xl font-retro">Welcome to 1UP Wrestling</h1>
      <img src="/images/1up-controller.png" alt="1UP Wrestling" class="mx-auto w-full lg:w-1/2" />
      <h1 class="text-xl font-retro">Our Mission</h1>
      <p class="my-4">To provide wrestlers a platform to learn their craft. We want to give our area a product that we can be proud of. This company's goal is to run shows regularly and to provide family fun entertainment by bringing them a great in-ring product that is driven by great storylines centered around the world of professional wrestling. </p>
      <router-link to="/about" class="mt-4 inline-block bg-gray-900 text-yellow-300 py-2 px-4 font-retro border-2 border-yellow-300">Learn More About Us</router-link>
    </section>
    <EventSummary :event="nextEvent" :show-buttons="true" v-if="nextEvent"></EventSummary>
    <div class=text-center>
      <router-link to="/events" class="mt-8 inline-block bg-gray-900 text-yellow-300 py-2 px-4 font-retro border-2 border-yellow-300 mx-auto">View All Events</router-link>
    </div>
    <section class="roster bg-white p-4 shadow-lg mt-16 border-4 border-gray-900 text-center">
      <h2 class="text-2xl font-retro text-white bg-gray-900 p-2 mb-4 text-center">Choose Your Wrestler</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
        <WrestlerSummary v-for="wrestler in wrestlers" :key="wrestler.id" :wrestler="wrestler" />
      </div>
    </section>
    <div class=text-center>
      <router-link to="/roster" class="mt-8 inline-block bg-gray-900 text-yellow-300 py-2 px-4 font-retro border-2 border-yellow-300 mx-auto">View Full Roster</router-link>
    </div>
  </div>
</template>

<script>
import apiClient from '@/api/axios';
import WrestlerSummary from '@/components/WrestlerSummary.vue';
import EventSummary from '@/components/EventSummary.vue';

export default {
  components: {
    WrestlerSummary,
    EventSummary
  },
  data() {
    return {
      nextEvent: null,
      wrestlers: [],
    };
  },
  methods: {
    async fetchNextEvent() {
      const today = new Date().toISOString();
      const query = `
        query {
          events(where: { isActive: { equals: true }, dateTime: { gte: "${today}" } }, orderBy: { dateTime: asc }, take: 1) {
            id
            name
            dateTime
            venue {
              name
              address
            }
            image {
              url
            }
            ticketUrl
          }
        }
      `;
      const response = await apiClient.post('', { query });
      this.nextEvent = response.data.data.events[0];
    },
    async fetchWrestlers() {
      const query = `
        query {
          rosterItems(where: { isActive: { equals: true }, type: { equals: "wrestler" } }) {
            id
            name
            image {
              url
            }
          }
        }
      `;
      const response = await apiClient.post('', { query });
      const wrestlers = response.data.data.rosterItems;
      this.wrestlers = this.getRandomWrestlers(wrestlers, 4);
    },
    getRandomWrestlers(wrestlers, count) {
      const shuffled = wrestlers.sort(() => 0.5 - Math.random());
      return shuffled.slice(0, count);
    },
  },
  async created() {
    await this.fetchNextEvent();
    await this.fetchWrestlers();
  },
};
</script>
