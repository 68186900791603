<template>
  <div v-if="event">
    <section class="bg-white p-4 shadow-lg border-4 border-gray-900 flex flex-col lg:flex-row gap-4">
      <div>
        <img :src="event.image.url" :alt="event.name" class="border-4 border-gray-900 mx-auto" />
      </div>
      <div class="w-full text-center">
        <h1 class="text-3xl font-retro inline-block w-full text-white bg-gray-900 p-2">{{ event.name }}</h1>
        <h3 class="text-2xl font-retro mt-4">{{ formatDate(event.dateTime) }}</h3>
        <div class="text-xl" v-if="showButtons">First Bell: {{ formatTime(event.dateTime) }}</div>
        <div class="text-2xl font-retro mt-4">{{ event.venue.name }}</div>
        <div class="text-xl">{{ event.venue.address }}</div>
        <div v-if="showButtons">
          <a :href="`${event.ticketUrl}`" target="_blank" class="m-4 inline-block bg-red-600 text-white py-2 px-4 font-retro border-2 border-gray-900">Buy Tickets</a>
          <a :href="`https://www.google.com/maps/place/${event.venue.address}`" target="_blank" class="m-4 inline-block bg-red-600 text-white py-2 px-4 font-retro border-2 border-gray-900">Directions</a>
        </div>
      </div>
    </section>
    <h2 class="my-6 text-2xl font-retro text-white bg-gray-900 p-2 text-center">Matches</h2>
    <section class="bg-white p-4 shadow-lg border-4 border-gray-900 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
      <div v-for="match in event.matches" :key="match.id" class="text-center border-4 border-gray-900 p-4">
        <p class="text-2xl font-retro bg-gray-900 p-2 text-white w-full">{{ match.matchType.name }}</p>
        <div class="flex flex-row">
          <div class="p-4 grow">
            <p class="text-xl font-retro">Player 1</p>
            <p class="text-xl" v-for="player in match.player1" :key="player.id">{{ player.name }}</p>
          </div>
          <div class="p-4 grow">
            <p class="text-xl font-retro">Player 2</p>
            <p class="text-xl" v-for="player in match.player2" :key="player.id">{{ player.name }}</p>
          </div>
        </div>
        <p class="text-2xl font-retro bg-gray-900 p-2 text-white w-full" v-if="hasWinner(match)">Winner</p>
        <p class="text-xl p-4" v-if="hasWinner(match)">{{ formatWinner(match) }}</p>
      </div>
    </section>
    <div class="text-center">
      <router-link to="/events" class="mt-4 inline-block bg-gray-900 text-yellow-300 py-2 px-4 font-retro border-2 border-yellow-300 mx-auto">View
        All Events</router-link>
    </div>
  </div>
  <div v-else>
    <p>Loading...</p>
  </div>
</template>

<script>
import apiClient from '@/api/axios';
import { format, parseISO, isFuture } from 'date-fns';

export default {
  data() {
    return {
      event: null,
      showButtons: false
    };
  },
  methods: {
    async fetcEvent() {
      const id = this.$route.params.id;
      const query = `
        query {
          event(where: { id: "${id}" }) {
            id
            name
            dateTime
            venue {
              name
              address
            }
            image {
              url
            }
            ticketUrl
            youtubeLinks
            matches {
              id
              matchType {
                name
              }
              winType {
                name
              }
              player1 {
                id
                name
              }
              player2 {
                id
                name
              }
              winner 
            }
          }
        }
      `;
      const response = await apiClient.post('', { query });
      this.event = response.data.data.event;
      this.showButtons = isFuture(parseISO(this.event.dateTime));
    },
    formatDate(dateTime) {
      const date = parseISO(dateTime);
      return `${format(date, 'EEEE, MMMM do, yyyy')}`;
    },
    formatTime(dateTime) {
      const date = parseISO(dateTime);
      return `${format(date, 'h:mm a')}`;
    },
    hasWinner(match) {
      return match.winner !== 'null';
    },
    formatWinner(match) {
      if (match.winner === 'player1') {
        return `${match.player1.map(player => player.name).join(', ')} won by ${match.winType.name}`;
      } else if (match.winner === 'player2') {
        return `${match.player2.map(player => player.name).join(', ')} won by ${match.winType.name}`;
      }
      else {
        return '';
      }
    }
  },
  async created() {
    await this.fetcEvent();
  },
}
</script>
