<template>
  <div v-if="rosterItem">
    <!-- Wrestler Template -->
    <section v-if="rosterItem.type === 'wrestler'" class="bg-white p-4 shadow-lg border-4 border-gray-900 flex flex-col lg:flex-row gap-4">
      <div class="border-4 border-gray-900 bg-red-600">
        <img :src="rosterItem.image.url" :alt="rosterItem.name" class="mx-auto w-96" />
      </div>
      <div class="w-full text-center">
        <h1 class="text-4xl font-retro inline-block w-full text-white bg-gray-900 p-2">{{ rosterItem.name }}</h1>
        <p v-if="rosterItem.nickname" class="text-2xl">"{{ rosterItem.nickname }}"</p>
        <p class="text-xl mt-4">Hometown: {{ rosterItem.hometown }}</p>
        <p class="text-xl">Weight: {{ rosterItem.weight }}</p>
        <div v-if="rosterItem.relatedTeams && rosterItem.relatedTeams.length">
          <h2 class="text-2xl font-retro mt-8">
            Member of <span v-for="team in rosterItem.relatedTeams" :key="team.id"><router-link :to="`/roster/${team.id}`" class="hover:underline">{{ team.name }} </router-link></span>
          </h2>
        </div>
      </div>
    </section>

    <!-- Team Template -->
    <section v-if="rosterItem.type === 'team'" class="bg-white p-4 shadow-lg mt-4 border-4 border-gray-900 flex flex-col gap-4">
      <h1 class="text-4xl font-retro inline-block w-full text-white bg-gray-900 p-2 text-center">{{ rosterItem.name }}</h1>
      <h2 class="text-2xl font-retro">Team Members:</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">        
        <WrestlerSummary v-for="member in rosterItem.relatedWrestlers" :key="member.id" :wrestler="member" />
      </div>
    </section>
    <div class="text-center">
      <router-link to="/roster" class="mt-4 inline-block bg-gray-900 text-yellow-300 py-2 px-4 font-retro border-2 border-yellow-300 mx-auto">View Full Roster</router-link>
    </div>
  </div>
  <div v-else>
    <p>Loading...</p>
  </div>
</template>

<script>
import apiClient from '@/api/axios';
import WrestlerSummary from '@/components/WrestlerSummary.vue';

export default {
  components: {
    WrestlerSummary
  },
  data() {
    return {
      rosterItem: null,
    };
  },
  methods: {
    async fetchRosterItem() {
      const id = this.$route.params.id;
      const query = `
        query {
          rosterItem(where: { id: "${id}" }) {
            id
            name
            nickname
            hometown
            image {
              url
            }
            weight
            type
            relatedWrestlers {
              id
              name
              image {
                url
              }
            }
            relatedTeams {
              id
              name
            }
          }
        }
      `;
      const response = await apiClient.post('', { query });
      this.rosterItem = response.data.data.rosterItem;
    }
  },
  async created() {
    await this.fetchRosterItem();
  },
  watch: {
    '$route.params.id': {
      handler () {
        this.fetchRosterItem();
      }
    }
  },
};
</script>

<style scoped>
/* Additional styles if needed */
</style>
