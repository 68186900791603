<template>
    <section class="bg-white p-4 shadow-lg mt-4 border-4 border-gray-900 flex flex-col lg:flex-row gap-4">
        <div>
            <img :src="event.image.url" :alt="event.name" class="border-4 border-gray-900 mx-auto" />
        </div>
        <div class="w-full text-center">
            <router-link :to="`/events/${event.id}`" class="text-3xl font-retro inline-block w-full text-white hover:underline bg-gray-900 p-2">{{ event.name }}</router-link>
            <h3 class="text-2xl font-retro mt-4">{{ formatDate(event.dateTime) }}</h3>
            <div class="text-xl" v-if="showButtons">First Bell: {{ formatTime(event.dateTime) }}</div>
            <div class="text-2xl font-retro mt-4">{{ event.venue.name }}</div>
            <div class="text-xl">{{ event.venue.address }}</div>
            <div v-if="showButtons">
                <a :href="`${event.ticketUrl}`" target="_blank" class="m-4 inline-block bg-red-600 text-white py-2 px-4 font-retro border-2 border-gray-900">Buy Tickets</a>
                <a :href="`https://www.google.com/maps/place/${event.venue.address}`" target="_blank" class="m-4 inline-block bg-red-600 text-white py-2 px-4 font-retro border-2 border-gray-900">Directions</a>
            </div>
        </div>
    </section>
</template>

<script>
import { format, parseISO } from 'date-fns';
export default {
    props: {
        event: {
            type: Object,
            required: true
        },
        showButtons: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        formatDate(dateTime) {
            const date = parseISO(dateTime);
            return `${format(date, 'EEEE, MMMM do, yyyy')}`;
        },
        formatTime(dateTime) {
            const date = parseISO(dateTime);
            return `${format(date, 'h:mm a')}`;
        }
    }
}
</script>

<style scoped>
/* Additional styles if needed */
</style>
