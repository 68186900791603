<template>
  <div>
    <section class="hero bg-red-600 text-white p-4 text-center">
      <img src="/images/logo-large.png" alt="1UP Wrestling" class="mx-auto my-6" />
      <h1 class="text-2xl md:text-4xl xl:text-6xl font-retro">Our Mission</h1>
      <p class="mt-4">To provide wrestlers a platform to learn their craft. We want to give our area a product that we can be proud of. This company's goal is to run shows regularly and to provide family fun entertainment by bringing them a great in-ring product that is driven by great storylines centered around the world of professional wrestling.</p>
    </section>
    <section class="company-info bg-white p-6 shadow-lg mt-6 border-4 border-gray-900 text-center">
      <h2 class="text-2xl font-retro text-white bg-gray-900 p-2 text-center">About 1UP Wrestling</h2>
      <p class="text-xl font-retro mt-4">Ding, ding, ding!!!</p>
      <p class="mt-4">Get ready to press start with 1-Up Wrestling! We are a brand new professional wrestling company based out of Syracuse, New York. 1-Up Wrestling is proud to bring live pro wrestling action back to Central New York. Our family friendly shows combine the modern style with the classic fundamentals to create the magic of pro wrestling that you know and love! </p>
      <p class="my-4">Which larger than life characters will become the heroes that you cheer on to victory or the despicable villains that you boo out of the building? Our diverse roster will consist of established local stars as well as up and coming rookies looking to climb the ranks. Don't be surprised to see some major superstars from the past and present show up!</p>
      <p class="mb-4">We are going to give you a monthly dose of incredible athleticism, jaw dropping moments, and special memories that will make this the go to place to get your pro wrestling fix! Come out to the matches with 1-Up Wrestling for a unique experience and share your passion for pro wrestling with fans of all ages!</p>
      <p class="text-xl font-retro">Ding, ding, ding!!!</p>
    </section>
    <!-- <section class="owners bg-white p-6 shadow-lg mt-6 border-4 border-gray-900">
      <h2 class="text-2xl font-retro text-white bg-gray-900 p-2 text-center">Owners</h2>
    </section>
    <section class="staff bg-white p-6 shadow-lg mt-6 border-4 border-gray-900">
      <h2 class="text-2xl font-retro text-white bg-gray-900 p-2 text-center">Staff</h2>
    </section> -->
  </div>
</template>

<script>
export default {
  // Logic to fetch company info, owners, and staff goes here
}
</script>
