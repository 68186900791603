import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import AboutUsPage from '../views/AboutUsPage.vue';
import EventsPage from '../views/EventsPage.vue';
import EventDetailPage from '../views/EventDetailPage.vue';
import RosterPage from '../views/RosterPage.vue';
import RosterItemDetailPage from '../views/RosterItemDetailPage.vue';
import ContactUsPage from '../views/ContactUsPage.vue';

const routes = [
  { path: '/', name: 'Home', component: HomePage },
  { path: '/about', name: 'AboutUs', component: AboutUsPage },
  { path: '/events', name: 'Events', component: EventsPage },
  { path: '/events/:id', name: 'EventDetail', component: EventDetailPage },
  { path: '/roster', name: 'Roster', component: RosterPage },
  { path: '/roster/:id', name: 'RosterItemDetail', component: RosterItemDetailPage },
  { path: '/contact', name: 'ContactUs', component: ContactUsPage }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
