<template>
  <div id="app">
    <AppHeader />
    <main class="container mx-auto p-4 bg-red-600 mt-0 border-4 border-gray-900"> <!-- Adjusted padding and margin-top -->
      <router-view />
    </main>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  components: {
    AppHeader,
    AppFooter
  }
}
</script>
